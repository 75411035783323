
export default {
    /**
     * Run calculation on field, where is predefined data, but result is empty for calculation
     */
    runCalculationsOnPrefilledLines(){
        const calculations = {}; // {resultColumnKey: [dependencyFields]}
        this.formulasBuffer = this.props.def.get('formula').toJS();
        this.props.def.get('fields').toJS().forEachObject((key) => {
            const formula = this.findFormulaForColumn(key);
            if(formula === false || formula == null){
                return; // continue with next field
            }
            for (const resultColumnKey in formula){
                const resultColumnValue = this.props.data.get(resultColumnKey);
                if(resultColumnValue != null && resultColumnValue !== 'RECALCULATE_CUSTOM_LINE'){
                    continue; // data already filled -> no calculation
                }
                // find all dependency fields for calculation:
                calculations[resultColumnKey] = [];
                for (const operand of formula[resultColumnKey]){
                    if(operand.type !== 'var'){
                        continue;
                    }
                    // we found variable from lineRecord
                    const dependencyColumnKey = operand.value
                    const value = this.props.data.get(dependencyColumnKey)
                    if(value == null){
                        // some dependency don't have value, can't perform calculation
                        delete(calculations[resultColumnKey])
                        break;
                    }
                    calculations[resultColumnKey].push(dependencyColumnKey);
                }
            }
            for (const resultColumnKey in calculations){
                const dependencyKeyToStartMath = calculations[resultColumnKey][0];
                if(dependencyKeyToStartMath){
                    // eslint-disable-next-line no-console
                    console.log(`DoingMath, line: ${this?.props?.lineKey} result is: ${resultColumnKey}, dependency: `, calculations[resultColumnKey]);
                    this.doMath(dependencyKeyToStartMath);
                }
            }
        });
        this.formulasBuffer = null;
    },


    findFormulaForColumn(key){
        const formulas = this.formulasBuffer ?? this.props.def.get('formula').toJS();
        if (!formulas || !formulas[key]) {
            return false;
        }
        if (typeof formulas[key] === 'string') {
            key = this.getFormulaReferenceKey(formulas[key], formulas);
        }
        const formulaName = this.getFormulaName(key, formulas);
        return formulas[key][formulaName];
    }
}