/**
 *
 */
export default function routeAdmin() {
    const page = window.location.hash.replace('#', '').split('/');

    if (page[1]) {
        this.dsAdd('set', 'view', {}, 'admin');
        if (page[3] != undefined) {
            this.dsAdd('set', 'view/customData/params', page[3]);
        }
        this.dsProcess();
        return;
    }

    this.rest.post('admin/getdashboard', {}, (data) => {
        if (data.status === 'error') {
            return;
        }
        if(data.status === false) {
            this.error(this.translate(data?.errorMessage?.text || data?.message?.text))
            return;
        }
        this.dsAdd('set', 'view', {}, 'admin');

        data.sections.forEach((section, i) => {
            this.dsAdd('add', 'view/sections', {title: section.title, description: section.description, icon: section.icon}, 'adminSections');
            section.rows.forEach((row, k) => {
                this.dsAdd('add', 'view/sections/' + i + '/rows', row, 'adminSectionsColumnsRows');
            });
        });
        this.dsProcess();
    });
}
