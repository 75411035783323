import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import sAction from 'sAction';
import Button from 'ROOT/src/components/formElements/Button';
import ViewPanelMenuOthers from 'ROOT/src/components/viewPanelMenu/viewPanelMenuOthers';
import ImmutablePropTypes from 'react-immutable-proptypes';
import useSaveEditView from 'ROOT/src/hooks/EditView/useSaveEditView';
import useResetDetailView from 'ROOT/src/hooks/EditView/useResetDetailView';
import {EditViewContext} from 'ROOT/src/contexts/EditViewContext';
import TooltipWrapper from 'ROOT/src/components/Tooltip/TooltipWrapper';

/**
 * Vykresluje horni tlacitka u detailview
 *
 * @TODO refactoring
 * @TODO odstranit z props props.modul
 */
export default class ViewPanelMenu extends PureComponent {
    static contextType = EditViewContext;
    constructor(props) {
        super(props);
        this.state = {
            buttonLimit: -1,
        };
        this.panel = React.createRef();
    }

    /**
     * Calculates, how many buttons can be displayed, before they overflow page
     */
    maxNumberOfButtons() {
        // prepocitani limitu poctu tlacitek, kolik se jich vleze na sirku obrazovky
        let panelWidth = this.panel.current.clientWidth;
        panelWidth = panelWidth - 160;
        const buttons = this.panel.current.querySelectorAll('.acmButton');
        let buttonsWidth = 0;
        let doUpdate = true;
        buttons.forEach((button, index) => {
            const buttonWidth = button.clientWidth;
            buttonsWidth += buttonWidth + 10;
            if (buttonsWidth > panelWidth && doUpdate === true) {
                this.setState({
                    buttonLimit: index,
                });
                doUpdate = false;
            }
        });
    }
    componentDidUpdate() {
        this.maxNumberOfButtons();
    }

    componentDidMount() {
        this.maxNumberOfButtons();
    }

    /**
     * @param {string} action
     * @param {string} buttonId
     * @param {object} params
     */
    onClick(action, buttonId, params) {
        const prefix = this.props.prefix;
        const data = this.props;
        const paramData = {
            prefix: prefix,
            module:
                this.props.module != null ? this.props.module : this.props.modul,
            id: data.id,
            type: data.type,
            name: data.name,
            buttonId: buttonId,
        };

        // pokud se klika na nejake tlacitko v listu, poslou se rovnou i id zaznamu + pripadny filtr
        // todo: udelat refactorizaci starych kodu, kdy se tohle delalo ve funkcich zvlast
        if (!paramData.id && data.selected) {
            paramData.id = data.selected;
            if (paramData.id === 'all') { // Kdyz se klikne na Oznacit vsechny zaznamy
                paramData.filter = sAction.getListviewFilter(prefix, data);
            }
        }

        if (action != null && sAction[action] != null) {
            if (params != null) {
                params.forEach((param) => {
                    if (param.get('type') === 'store') {
                        paramData[param.get('name')] = data[param.get('value')];
                    } else if (param.get('type') === 'selector') {
                        const element = document.querySelector(param.get('value'));
                        paramData[param.get('name')] = element.value;
                    } else {
                        paramData[param.get('name')] = param.get('value');
                    }
                });
            }
            sAction[action](paramData);
        } else {
            console.error('akce "' + action + '" není definována');
        }
    }

    render() {
        const data = this.props;
        const prefix = this.props.prefix;
        const renderButtons = [];
        const renderButtonsOthers = [];
        let returnChangesDisabled = true;
        const {
            view,
            selectedGroupingOpt,
            module,
            level,
            levels,
            setLevel,
            saveField,
            fieldsWhitelist,
            fields,
        } = this.context;

        if (sAction.getViewName() === 'detail' && data.type !== 'popup') {
            if (
                (data.changes.get('fields').size > 0 ||
                    (data.changes.get('customData') &&
                        data.changes.get('customData').size > 0
                    ) ||
                    data.changes.get('forceChange')
                ) &&
                data.id != null
            ) {
                returnChangesDisabled = false;
            }

            const btnDisabled = this.props.mode === 'edit' || returnChangesDisabled;

            renderButtons.push(
                <TooltipWrapper key={'returnChanges'} label={'LBL_RETURN_CHANGES'} disabled={btnDisabled}>
                    <Button
                        onClick={() => sAction.removeChangesOnRecord(prefix)}
                        disabled={btnDisabled}
                        id={'actionButtonReturnChanges'}
                    >
                        <React.Fragment>
                            <div
                                className={'actionPanelButtonIcon ' + 'icon-undo'}
                                id={'returnChangesIcon'}
                            />
                        </React.Fragment>
                    </Button>
                </TooltipWrapper>,
            );
            renderButtons.push(
                <div
                    className="viewPanelButtonsDelimiter"
                    key={'returnChanges' + '_delimiter'}
                />,
            );
        }
        if (this.props.mode === 'edit') {
            renderButtons.push(
                <Button
                    onClick={() => useSaveEditView(view, this.props.module, level, selectedGroupingOpt)}
                    className='hoverGreen'
                    key='saveEv'
                >
                    <div className='actionPanelButtonIcon icon-saveIcon'/>
                    {sAction.translate('LBL_EV_SAVE_EV')}
                </Button>,
            );
            renderButtons.push(<div key='delimiterOne' className="viewPanelButtonsDelimiter"/>);
            renderButtons.push(
                <Button
                    onClick={() => sAction.dataSet(`${this.props.prefix}/mode`, '')}
                    className='hoverRed'
                    key='cancelAction'
                >
                    <div className='actionPanelButtonIcon icon-undo'/>
                    {sAction.translate('LBL_CANCEL_ACTION')}
                </Button>,
            );
            renderButtons.push(<div key='delimiterTwo' className="viewPanelButtonsDelimiter"/>);
            renderButtons.push(
                <Button
                    key='newField'
                    onClick={() => {
                        sAction.dynPopup('EditView/FieldEdit/FieldEditContainer.js', {
                            level: level,
                            levels: levels,
                            setLevel: setLevel,
                            fieldDef: {},
                            newField: true,
                            defs: {},
                            fields: fields,
                            module: module,
                            fieldsWhitelist: fieldsWhitelist,
                        });
                    }}
                >
                    <div className='actionPanelButtonIcon icon-addIcon'/>
                    {sAction.translate('LBL_EV_NEW_FIELD')}
                </Button>,
            );
            renderButtons.push(<div key='delimiterThree' className="viewPanelButtonsDelimiter"/>);
            renderButtons.push(
                <Button key='resetEv' onClick={() => useResetDetailView(this.props.module, selectedGroupingOpt)}>
                    <div className='actionPanelButtonIcon icon-deleteIcon'/>
                    {sAction.translate('LBL_EV_RESET_VIEW')}
                </Button>,
            );
        } else {
            data.buttons.forEach((button, index) => {
                const buttonAcl =
                    button.def.get('acl') == null ? 'edit' : button.def.get('acl');
                const acl = data.acl;
                if (acl != null && !acl.get(buttonAcl)) {
                    return;
                } else if (acl == null) {
                    if (
                        sAction.hasAccess(
                            data.module == null ? data.modul : data.module,
                            buttonAcl,
                        ) === false
                    ) {
                        return;
                    }
                }

                let buttonClass = '';
                if (button.def.get('extraClass') != null) {
                    buttonClass += ' ' + button.def.get('extraClass');
                }
                const actionName = button.def.get('onClick');

                let params = button.def.get('params');
                if (params == null) {
                    params = [];
                }
                let disabled = false;
                if (
                    data.selected !== undefined &&
                    data.selected.size === 0 &&
                    button.def.get('multiple')
                ) {
                    disabled = true;
                }

                if (
                    data.changes !== undefined &&
                    (data.changes.get('fields').size > 0 ||
                        (data.changes.get('customData') &&
                            data.changes.get('customData').size > 0
                        ) ||
                        data.changes.get('forceChange')
                    ) &&
                    data.id != null &&
                    button.def.get('savedRecord') === true
                ) {
                    disabled = true;
                }

                let add = true;
                if (
                    button.def.get('newRecord') === false &&
                    (data.id === '' || data.id == null
                    )
                ) {
                    add = false;
                }
                if (add === true) {
                    const buttonRender = (
                        <Button
                            onClick={() => this.onClick(actionName, button.id, params)}
                            className={buttonClass}
                            key={button.id}
                            id={button.id}
                            disabled={this.props.mode === 'edit' || disabled}
                        >
                            {button.def.get('iconClass') != null && (
                                <div
                                    className={
                                        'actionPanelButtonIcon ' +
                                        'icon-' +
                                        button.def.get('iconClass')
                                    }
                                    title={sAction.getStorage('debug') ? actionName : undefined}
                                />
                            )}
                            {sAction.translate(button.def.get('label'), data.module)}
                        </Button>
                    );
                    if (button.def.get('others')) {
                        renderButtonsOthers.push(buttonRender);
                    } else {
                        if (+this.state.buttonLimit === -1 || index < this.state.buttonLimit) {
                            renderButtons.push(buttonRender);
                            renderButtons.push(
                                <div
                                    className="viewPanelButtonsDelimiter"
                                    key={button.id + '_delimiter'}
                                />,
                            );
                        } else {
                            renderButtonsOthers.push(buttonRender);
                        }
                    }
                }
            });
        }

        // _______________________________________________ stránkování mezi záznamy
        let beforeNext = null;
        if (data.id) {
            const lastSearch = sAction.getStorage('listViewSearch');
            if (lastSearch && lastSearch.module === data.module) {
                const number =
                    lastSearch.data.offset +
                    (sAction.getStorage('listViewSearchIndex') || 0
                    ) +
                    1;
                beforeNext = (
                    <div className="detailPagination">
                        {number > 1 && (
                            <TooltipWrapper label={'LBL_PREVIOUS_PAGE'}>
                                <div
                                    className="arrow icon-pageBack"
                                    onClick={() => sAction.changeRecord('back', prefix, data.type)}
                                />
                            </TooltipWrapper>
                        )}
                        {number}
                        <TooltipWrapper label={'LBL_NEXT_PAGE'}>
                            <div
                                className="arrow icon-pageNext"
                                onClick={() => sAction.changeRecord('next', prefix, data.type)}
                            />
                        </TooltipWrapper>
                    </div>
                );
            }
        }

        //   console.log(renderButtonsOthers);
        return (
            <div className="viewActionPanel" ref={this.panel}>
                <div className="viewActionPanelButtons">
                    {renderButtons}
                    {renderButtonsOthers.length !== 0 && this.props.mode !== 'edit' &&
                        <ViewPanelMenuOthers buttons={renderButtonsOthers} buttonLimit={this.state.buttonLimit}/>
                    }
                </div>
                {beforeNext}
            </div>
        );
    }
}

ViewPanelMenu.propTypes = {
    prefix: PropTypes.string.isRequired,
    module: PropTypes.string.isRequired,
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    acl: ImmutablePropTypes.mapContains({
        delete: PropTypes.bool,
        detail: PropTypes.bool,
        edit: PropTypes.bool,
        export: PropTypes.bool,
        massupdate: PropTypes.bool,
    }),
    buttons: ImmutablePropTypes.listOf(
        ImmutablePropTypes.recordOf({
            id: PropTypes.string,
            def: ImmutablePropTypes.mapContains({
                extraClass: PropTypes.string,
                iconClass: PropTypes.string,
                id: PropTypes.string,
                label: PropTypes.string,
                onClick: PropTypes.string,
                params: ImmutablePropTypes.list,
            }),
        }),
    ),
    changes: ImmutablePropTypes.mapContains({
        customData: PropTypes.object,
        fields: ImmutablePropTypes.map,
        files: ImmutablePropTypes.list,
        forceChange: PropTypes.bool,
    }),
    selected: PropTypes.object,
    canSave: PropTypes.bool,
    modul: PropTypes.string,
    mode: PropTypes.string,
};
