/**
 * @param {string | number} row
 * @param {string | number} line
 * @param {bool} value
 * @deprecated
 */
export default function cellToEdit(row, line, value) {
    this.dsClear();
    this.dsAdd('set', `view/rows/${row}/records/${line}/actEdit`, value);
    this.dsProcess();
}
