import React from 'react';
import PropTypes from 'prop-types';
import PureComponent from '../../pure';
import sAction from 'sAction';

export default class CustomLinesTotal extends PureComponent {
    constructor(props) {
        super(props);
    }

    /**
     * @param {string} col
     * @param {string} key
     * @returns JSX.Element
     */
    getField(col, key) {
        const lines = this.props.lines;
        const moduleData = this.props.moduleData;
        let sum = 0;
        if (lines) {
            lines.forEach((line) => {
                if ((line.get(col) || line.get(col) === 0) && !line.get('deleted')) {
                    sum = sum + parseFloat(line.get(col));
                }
            });
        }
        let className = 'customLinesCell';

        let value = Math.round(sum * 100) / 100;
        const type = moduleData.get(col).get('type');
        if (type === 'currency' || type === 'float') {
            className += ' alignRight';
            value = parseFloat(value).toFixed(2);
        }
        let style = null;
        const def = this.props.def;
        if (def.get && def.get('style') && def.get('style').get && def.get('style').get(col)) {
            style = def.get('style').get(col).toJS();
        }

        return (
            <div className={className} key={key} style={style}>{value}</div>
        );
    }

    /**
     *
     * @param {string} col
     * @param {string} key
     * @param {string} className
     * @returns JSX.Element
     */
    getEmptyCell(col, key, className = '') {
        let style = null;
        const def = this.props.def;
        if (def.get && def.get('style') && def.get('style').get && def.get('style').get(col)) {
            style = def.get('style').get(col).toJS();
        }
        return (<div className={'customLinesCell ' + className} key={key} style={style}></div>);
    }

    /**
     * @returns JSX.Element
     */
    getLineButtons() {
        let minWidth = 0;
        if (sAction.hasAccess(this.props.info.get('module'), 'detail')) {
            if (this.props.info.get('eyeIcon')) {
                minWidth += 14;
            }
            if (this.props.info.get('detailButton')) {
                minWidth += 14;
            }
        }
        if (sAction.hasAccess(this.props.info.get('module'), 'edit')) {
            if (this.props.info.get('orderRow') && this.props.info.get('ordering') === 'manual') {
                minWidth += 14;
            }
            minWidth += 16;
        }

        return (
            <div className='customLinesCell customLinesCellDelete' key={'buttons'} style={{minWidth: minWidth + 'px'}}>
            </div>
        );
    }

    render() {
        const def = this.props.def;
        const moduleData = this.props.moduleData;
        const info = this.props.info;

        const cols = [];
        const totalCols = def.get('total').toJS();
        let style = null;
        def.get('fields').toJS().forEachObject((col, key) => {
            if (!cols.length) {
                if (def.get && def.get('style') && def.get('style').get && def.get('style').get(col)) {
                    style = def.get('style').get(col).toJS();
                }
            }
            if (totalCols.includes(col)) {
                cols.push(this.getField(col, key));
            } else {
                cols.push(this.getEmptyCell(col, key));
            }
        });

        const altCols = [];

        def.get('fields').toJS().forEachObject((col, key) => {
            if (totalCols.includes(col)) {
                altCols.push([
                    <div key={key} className={'customLinesCellTotalRow'}>
                        <label className={'customLinesCellLabel'}>
                            {sAction.translate(moduleData.get(col).get('vname'), info.get('module'))}
                        </label>
                        {this.getField(col, key)}
                    </div>,
                ]);
            }
        });

        cols[0] = (<div className='customLinesCell' key={0} style={style}>{sAction.translate('LBL_TOTAL')}</div>);
        const lineButtons = this.getLineButtons();

        return (
            <div className='customLinesRowContainer customLinesFooter'>
                {lineButtons}
                {cols}
                <div className='customLinesCellRoot'>
                    <span className=''>{sAction.translate('LBL_TOTAL')}</span>
                </div>
                <div className='customLinesCellRoot'>
                    {altCols}
                </div>
            </div>
        );
    }
}

CustomLinesTotal.propTypes = {
    lines: PropTypes.any,
    moduleData: PropTypes.any,
    def: PropTypes.any,
    info: PropTypes.any,
};
