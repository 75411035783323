import React from 'react';
import FormField from './formField.js';
import sAction from 'sAction';
import InputText from '../formElements/InputText';
import TooltipWrapper from 'ROOT/src/components/Tooltip/TooltipWrapper';

export default class DefaultInputField extends FormField {
    constructor() {
        super();
        this.state = {
            state: 'fine',
            showPassword: false,
        };
        this.input = React.createRef();
    }
    componentDidMount() {
        super.componentDidMount();
        const newRecord = this.props.newRecord;
        if (!newRecord) {
            //    this.refs.input.focus();
            //  this.refs.input.select();
        }
        this.defaultValue = this.getValue();
        if ( this.state.ok !== false) {
            sAction.dataSet(this.props.way+'/def/error', false);
        }
    }
    /**
     * @returns {*}
     */
    getValue() {
    // return "";
        return this.input.current.value;
    }
    /**
     * @param {object} param
     */
    save(param = {}) {
        const value = this.getValue();
        // Pokud prijde v way report nechceme provovalat saveField
        // je potreba provolal onBlur predany z prislusneho fieldu
        if (this.props.way === 'report' && this.props.onBlur) {
            this.props.onBlur(value);

            return;
        }
        if (this.check()) {
            const data = {
                way: this.props.way,
                name: this.props.name,
                value,
                ...param,
            };
            this.saveField(data);
        }
    }
    /**
     * @param {object} data
     */
    saveField(data) {
        sAction.saveField(data);
    }
    /**
     * @returns bool
     */
    check() {
        let required = this.props.def?.get('required');
        if (required === undefined) {
            required = false;
        }
        const reg = this.state.reg;
        const value = this.getValue();
        let ok = true;

        if (required === true && (value === '' || value === null)) {
            ok = false;
            this.setState({ok: ok});
        } else if (required === true && value) {
            if (reg != null && !reg.test(value)) {
                ok = false;
                this.setState({ok: ok});
            }
        } else if (required === false && value) {
            if (reg != null && !reg.test(value)) {
                ok = false;
                this.setState({ok: ok});
            }
        }
        if (ok === false) {
            sAction.dataSet(this.props.way+'/def/error', (sAction.translate('LBL_WRONG_VALUE')));
            this.lockRecord();
        }

        return ok;
    }
    /**
     *
     */
    lockRecord() {
        sAction.dataSet(this.props.prefix + '/canSave', false);
    }
    /**
     *
     */
    cancel() {
        sAction.dataSet(this.props.way + '/actEdit', false);
        sAction.dataSet(this.props.way+'/def/error', false);
    }
    /**
     * @param {Event} e
     */
    onBlur(e) {
        const relTarget = e.relatedTarget;
        if (relTarget == null) {
            this.save();
        } else {
            const parent = relTarget.closest(
                'div[data-fieldname=\'' + this.props.name + '\']',
            );
            if (parent == null) {
                this.save();
            }
        }
    }
    /**
     * @param {Event} e
     */
    keyUpCall(e) {
        const keyCode = e.keyCode;
        if (keyCode === 13 && e.ctrlKey === false) {
            this.save();
        } else if (keyCode === 27) {
            this.cancel();
        }
    }
    render() {
        const newRecord = this.props.newRecord;
        const ok = this.state.ok;
        const containerClass = 'inputEditContainer';
        let error = false;
        if (ok === false) {
            //  inputClass += " wrongInput";
            error = true;
        }
        const errorValue = this.props.def?.get('error');
        let value = this.props.value;
        if (newRecord && (!value)) {
            value = '';
        } else {
            value = sAction.decodeHTMLEntities(value);
        }

        const fieldType = this.props.def?.get('type');
        const displayType = fieldType === 'password' && !this.state.showPassword ? 'password' : 'text';

        return (
            <div className={containerClass} data-fieldname={this.props.name} id={'input_' + this.props.name}>
                <div className="inputContainer">
                    <InputText
                        autoFocus={true}
                        onKeyUp={(e) => this.keyUpCall(e)}
                        onKeyDown={(e) => this.onKeyDown(e)}
                        myRef={this.input}
                        inputProps={{maxLength: this.props.def?.get('len')}}
                        onBlur={(e) => this.onBlur(e)}
                        error={error}
                        defaultValue={value}
                        id={this.props.name}
                        name={this.props.name}
                        type={displayType}
                        fieldtype={this.props.def?.get('type')}
                    />
                </div>

                {!newRecord && (
                    <div className="buttonContainer">
                        {errorValue && (
                            <div
                                tabIndex="-1"
                                className="warningMessage inputEditButton"
                            >
                                <TooltipWrapper title={errorValue} show={!!errorValue} mode={'error'}>
                                    <div className={'icon-warning'} />
                                </TooltipWrapper>
                            </div>
                        )}
                        {fieldType === 'password' && (
                            <div
                                tabIndex="2"
                                onClick={() => {
                                    this.setState({showPassword: !this.state.showPassword});
                                }}
                                className="inputEditButton"
                            >
                                <div className={'icon-eye'} />
                            </div>
                        )}
                        <div
                            tabIndex="2"
                            onClick={() => this.save()}
                            className="inputEditButton"
                        >
                            <TooltipWrapper label={'LBL_CONFIRM_BUTTON_LABEL'}>
                                <div className={'icon-detailConfrim'} />
                            </TooltipWrapper>
                        </div>
                        <div
                            tabIndex="3"
                            onClick={() => this.cancel()}
                            className=" inputEditButton"
                        >
                            <TooltipWrapper label={'LBL_CANCEL_ACTION'}>
                                <div className={'icon-detailCancel'} />
                            </TooltipWrapper>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
