import PropTypes from 'prop-types';
import React from 'react';
import sAction from 'sAction';

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
            info: null,
            logId: Math.random().toString(16).slice(2).substring(0, 8),
            visibleJson: false,
        };
    }

    /**
     * @param {Error} error
     * @param {ErrorInfo} info
     */
    componentDidCatch(error, info) { // Display fallback UI
        this.setState({hasError: true, error: error, info: info});
        // You can also log the error to an error reporting service
        this.logError(error, info);
        // eslint-disable-next-line no-console
        console.log('[ info ] logged fatal error', error, info);
        // eslint-disable-next-line no-console
        console.log('[ info ] Error logId: ', this.state.logId);
    }

    /**
     * @param {*} error
     * @param {*} info
     */
    logError(error, info) {
        const store = sAction.store.getState().appReducer;

        const localStorageData = {};
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            const lcItem = localStorage.getItem(key).trim();
            if (lcItem !== null && lcItem && lcItem.length > 0) {
                try {
                    localStorageData[key] = JSON.parse(lcItem);
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.log('[ info ] Error parsing local storage item', key, lcItem);
                }
            }
        }

        const data = {
            logId: this.state.logId,
            error: error.toString().replace(/'/g, '"'),
            info: info,
            stack: error.stack.replace(/\n {2}/g, ' '),
            message: error.message,
            url: window.location.href,
            user: sAction.dataGet('conf/user/id'),
            browser: window.navigator.userAgent,
            resolution: window.screen.width + 'x' + window.screen.height,
            history: window.history,
            language: navigator.language,
            online: navigator.onLine,
            localStorage: localStorageData,
            store: store,
        };

        // eslint-disable-next-line no-console
        console.log('[ info ] logged fatal error', data);

        sAction.rest.post('logError', data, (returnData) => {
            if (returnData.status) {
                // eslint-disable-next-line no-console
                console.log('[ OK ] logError', returnData);
            } else {
                // eslint-disable-next-line no-console
                console.log('[ error ] logError failed: ', returnData);
            }
        }, false);
    }

    refreshPage = () => {
        window.location.reload(false);
    };

    toggleJson = () => {
        this.setState({visibleJson: !this.state.visibleJson});
    };

    render() {
        if (this.state.hasError) {
            const error = this.state.error;
            const info = this.state.info;

            const localStorageData = {};
            for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i);
                const lcItem = localStorage.getItem(key).trim();
                if (lcItem !== null && lcItem.length > 0) {
                    try {
                        localStorageData[key] = JSON.parse(lcItem);
                    } catch (e) {
                        // eslint-disable-next-line no-console
                        console.log('[ info ] Error parsing local storage item', key, lcItem);
                    }
                }
            }

            const data = {
                logId: this.state.logId,
                error: error.toString().replace(/'/g, '"'),
                info: info,
                stack: error.stack,
                message: error.message,
                url: window.location.href,
                user: sAction.dataGet('conf/user/id'),
                browser: window.navigator.userAgent,
                resolution: window.screen.width + 'x' + window.screen.height,
                history: window.history,
                language: navigator.language,
                online: navigator.onLine,
                // localStorage: localStorageData,
            };

            return (
                <div className="errorPage">
                    <a href="/"><img src="img/coripo_logo_dark.svg" alt="crm coripo logo" className="coripoLogo" /></a>

                    <div className="errorPageContent">

                        <img src="img/errorPageBackground.png" alt="crm coripo" className="errorPageBackground" />

                        <h1>Nepodařilo se zpracovat data.</h1>

                        <p className='errorPageUserMessage'>
                            Chyba byla zaznamenána, pokud se problém opakuje, kontaktujte administrátora s id chyby.
                        </p>

                        <h3 onClick={this.toggleJson}>
                            id: {this.state.logId}
                        </h3>
                        <br/>

                        <pre className={'jsonOutput' + (this.state.visibleJson ? ' open' : '')}>{JSON.stringify(data, undefined, 4)}</pre>

                        <div className="errorPageButtonsGrid">
                            <button className="reloadBtn" onClick={this.refreshPage}>
                                <span className="iconfas-reload"></span>
                                Zkusit znovu
                            </button>
                            <a href="/" className='backBtnLink'>
                                <button className="reloadBtn invertedBtn" >
                                    <span className="iconfas-back"></span>
                                    Zpět
                                </button>
                            </a>
                        </div>

                        {(typeof localStorage === 'object' && localStorage.length > 1) &&
                            <div className="errorPageButtonsGridBottom">
                                <p>
                                    Pokud se problém opakuje, zkuste vyčistit lokální paměť prohlížeče.
                                </p>
                                <div>
                                    <button className="reloadBtn invertedBtn" onClick={() => {
                                        localStorage.clear(); location.reload();
                                    }}>
                                        <span className="iconfas-clear"></span> Vyčistit paměť
                                    </button>
                                </div>
                            </div>
                        }

                    </div>

                    <img src="img/pomeranian_V3.svg" alt="crm coripo pes" className="errorPageImg" />
                </div>
            );
            // use this for normal response
            // return this.props.children;
        }

        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.any,
};
