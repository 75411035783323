import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {formatPhoneNumber, formatPhoneNumberIntl, getCountryCallingCode} from 'react-phone-number-input';

import PureComponent from '../pure';
import Enum from '../listViewFields/Enum';
import Multienum from '../listViewFields/Multienum';
import Currency from '../listViewFields/Currency';
import CurrencyId from '../listViewFields/CurrencyId';
import NameCallback from '../listViewFields/NameCallback';
import Name from '../listViewFields/Name';
import Url from '../listViewFields/Url';
import Date from '../listViewFields/Date';
import Datetime from '../listViewFields/Datetime';
import Relate from '../listViewFields/Relate';
import FileName from '../listViewFields/FileName';
import Multirelate from '../listViewFields/Multirelate';

import sAction from 'sAction';

export default class NewListViewField extends PureComponent {
    /**
     * invoke record select callback
     * @param {string} id record id
     */
    callback(id) {
        const prefix = this.props.prefix;
        if (
            this.props.selectCallBack !== undefined &&
      typeof this.props.selectCallBack === 'function'
        ) {
            this.props.selectCallBack(id, prefix);
        } else {
            console.error('Neexistující nebo špatně definovaný callback');
            console.error(this.props.selectCallBack);
        }
    }

    /**
     * handles resize of column by dragging
     * @param {PointerEvent} e click and drag event
     * @param {number} rowIndex resize column index
     * @param {string} type column field data type
     */
    resizeRow(e, rowIndex, type) {
        const widths = {
            parent: 280,
            relate: 250,
            enum: 180,
            multienum: 300,
            date: 120,
            datetime: 200,
            datetimecombo: 200,
        };
        if (widths[type]) {
            const width = widths[type];
            const containerRow = e.target.closest('.newListViewRow');
            const containerHeader = e.target
                .closest('.newListViewContentInner')
                .querySelectorAll('.newListViewHeaderCell')[rowIndex];
            if (containerRow.clientWidth < width) {
                containerRow.style.maxWidth = width + 'px';
                containerRow.style.minWidth = width + 'px';
                containerHeader.style.maxWidth = width + 'px';
                containerHeader.style.minWidth = width + 'px';
            }
        }
    }

    // zrusena editace zaznamu primo z listu (ctrl+leve_mysitko)
    // actEdit(e) {
    //     if (e.ctrlKey || e.metaKey) {
    //         /**
    //    * Need this because our version of React at the time of writing this
    //    * comment is 16.8.0 and 'e' event is nullified after this method so all
    //    * methods called from here using 'e' event (i.e. resizeRow)
    //    * couldn't access it otherwise.
    //    *
    //    * Will probably break in React 17+!
    //    * (https://reactjs.org/blog/2020/08/10/react-v17-rc.html#no-event-pooling)
    //    */
    //         e.persist();

    //         const index = this.props.index;
    //         const rowIndex = this.props.rowIndex;
    //         const row = this.props.row;
    //         const readOnly = row.def.get('readonly');
    //         const editInline = row.def.get('editInline');
    //         if (
    //             !readOnly &&
    //     editInline !== false &&
    //     sAction.hasAccess(this.props.modul, 'edit')
    //         ) {
    //             sAction.listviewHasAccess(this.props.prefix, index, (acl) => {
    //                 if (acl === true) {
    //                     this.resizeRow(e, rowIndex, row.def.get('type'));
    //                     sAction.cellToEdit(rowIndex, index, true);
    //                 }
    //             });
    //         }
    //     }
    // }

    /**
     * @return {React.JSX.Element|null}
     * @brief create icon for field and prepare data for popup for editing relationship fields
     */
    createRelationFieldEditIcon() {
        const row = this.props.row;
        const index = this.props.index;
        const line = this.props.line;
        const relationFieldDef = row.get('def').get('relationField') || null;
        if (!relationFieldDef) {
            return null;
        }

        const updateFieldName = row.key.toLowerCase();

        return <div
            className={'actionPanelButtonIcon icon-editField'}
            title={sAction.translate('LBL_UPDATE_RELATION_FIELD_OPEN')}
            onClick={(event) => { // eslint-disable-line
                const defaultValue = line.get('value');
                sAction.popup('UpdateRelationField', {
                    header: sAction.translate('LBL_UPDATE_RELATION_FIELD_POPUP_HEADER'),
                    data: {
                        subpanelModule: this.props.modul,
                        openedLineIndex: index, // which line / record we are editing in subpanel data
                        updateFieldName: updateFieldName,
                        // after reload subpanel after one change, data came in upper case :/
                        relationIdFieldName: relationFieldDef?.relationIdField?.toLowerCase(),
                        subpanelWay: this.props.prefix,
                        defaultValue: defaultValue,
                        savePlace: {[updateFieldName]: {value: defaultValue}},
                    },
                });
            }}
        />;
    }

    render() {
        const row = this.props.row;
        const line = this.props.line;
        // const editModule = this.props.editModule;
        const modul = this.props.modul;
        const grModule = row.def.get('fieldModule');
        const key = row.get('key');
        const listType = this.props.listType;
        const type = row.def.get('type');
        const index = this.props.index;
        // const rowIndex = this.props.rowIndex;
        const bcColor = this.props.bcColor;
        const prefix = this.props.prefix;
        const disabled = this.props.disabled;
        const inRightPanel = this.props.prefix.includes('rightPanel');
        let lineRender = null;
        let extraClass = '';
        // if (line.get('actEdit') && type !== 'multienum') {
        //     let fieldType = type;
        //     if (type === 'id' && (row.def.get('name') === 'currency_id' || row.def.get('name') === '_currency_id')) {
        //         fieldType = 'currency_id';
        //     }
        //     const F = sAction.listFields[fieldType] || sAction.listFields['varchar'];
        //     let fieldData;
        //     if (type === 'parent') {
        //         fieldData = {
        //             def: row.def,
        //             value: line.get('value'),
        //             name: row.def.get('name'),
        //             editModule,
        //             recordId: this.props.uuid,
        //             module: line.get('module') || null,
        //         };
        //     } else {
        //         fieldData = {
        //             def: row.def,
        //             value: line.get('value'),
        //             name: row.def.get('name'),
        //             editModule,
        //             recordId: this.props.uuid,
        //         };
        //     }
        //     const editableField = (
        //         <F
        //             way={prefix}
        //             listField={true}
        //             data={fieldData}
        //             rowIndex={rowIndex}
        //             recordIndex={index}
        //         />
        //     );
        //     let classes = '';
        //     if (type === 'currency') {
        //         classes = 'listViewCellRight';
        //     }
        //     return (
        //         <div className={'newListViewLine newListEditableField ' + classes}>
        //             {editableField}
        //         </div>
        //     );
        // }
        if (
            (row.key === 'NAME' || row.key === 'name' ||
      row.key === 'full_name' || row.key === 'FULL_NAME') &&
      (modul === row.def.get('fieldModule') ||
        grModule === 'Activities' ||
        grModule === 'History')
        ) {
            const Field = NameCallback;

            let value = line.get('value');
            if ( ! value || ! value.trim()) {
                value = '____';
            }

            let disMod = grModule;
            if (grModule === 'Activities' || grModule === 'History') {
                disMod = modul;
            }

            if (listType === 'popup') {
                return (
                    <Field
                        module={disMod}
                        id={line.get('id')}
                        value={value}
                        callback={() =>
                            this.callback({id: line.get('id'), name: line.get('value')})
                        }
                        extraClass={extraClass}
                    />
                );
            } else if (listType === 'rightPanelQuotes') {
                return (
                    <Field
                        module={disMod}
                        value={value}
                        id={this.props.uuid}
                        callback={() =>
                            this.callback({id: line.get('id'), name: line.get('value')})
                        }
                        extraClass="hrefEffect"
                    />
                );
            } else {
                const Field = Name;
                let rightPanelData = {};
                if (listType === 'subpanel' && ! inRightPanel) {
                    rightPanelData = {
                        'reloadList': prefix,
                    };
                }

                return (
                    <Field
                        module={disMod}
                        id={line.get('id')}
                        callback={(ev) => {
                            if (listType === 'subpanel' && ev) {
                                ev.target.closest('.subpanelContainer').classList.remove('maxSize');
                            }
                            sAction.setStorage('listViewSearchIndex', index);
                        }}
                        eyeCallBack={() => {
                            if (!prefix.includes('subpanel')) {
                                sAction.setStorage('listViewSearchIndex', index);
                            }
                            sAction.rightPanelDetail(disMod, line.get('id'), rightPanelData);
                        }}
                        value={value}
                        // onClick={(e) => this.actEdit(e)}
                        disabled={disabled}
                        inRightPanel={inRightPanel}
                        forceRightPanel={this.props.forceRightPanel}
                        target={this.props.target}
                    />
                );
            }
        } else if (row.key.toLowerCase() === 'document_name') {
            let value = line.get('value');
            if ( ! value || ! value.trim()) {
                value = '____';
            }
            if (listType === 'popup') {
                const Field = NameCallback;
                return (
                    <Field
                        value={value}
                        callback={() =>
                            this.callback({id: line.get('id'), name: line.get('value')})
                        }
                        extraClass={extraClass}
                    />
                );
            } else {
                return (
                    <FileName
                        module={row.def.get('fieldModule')}
                        value={value}
                        id={line.get('id')}
                        extraClass="hrefEffect"
                        onGetFile={(data) => sAction.downloadFile(data)}
                    />
                );
            }
        } else if (row.key.toLowerCase() === 'filename') {
            // if (this.props.modul === 'Notes'){
            let value = '____';
            let id = null;
            const module = 'FieldFile';

            if (line.get('value') && line.get('value')?.length !== 0 && line.get('value').trim()) {
                const splitId = line.get('value')?.split('::');
                value = splitId.length >= 2 ? splitId[1] : line.get('value');
                id = splitId.length >= 2 ? splitId[0] : line.get('id');
            }

            const fileData = {
                id: id,
                module: module,
                name: value,
                params: sAction.objectToFormdata({forceDownload: true}),
            };

            return (
                <FileName
                    module={row.def.get('fieldModule')}
                    value={value}
                    id={line.get('id')}
                    data={fileData}
                    extraClass="hrefEffect"
                    onGetFile={() => sAction.downloadFile(fileData) }
                />
            );
        } else if (row.key === row.def.get('fieldModule').toUpperCase() + '|NAME') {
            let value = line.get('value');
            if ( ! value || ! value.trim()) {
                value = '____';
            }
            if (line.get('id') !== '' && line.get('id') !== null) {
                return (
                    <Name
                        module={row.def.get('fieldModule')}
                        id={line.get('id')}
                        value={value}
                        eyeCallBack={() => {
                            sAction.setStorage('listViewSearchIndex', index);
                            sAction.rightPanelDetail(row.def.get('fieldModule'), line.get('id'), this.props.rightPanelData);
                        }}
                    />
                );
            } else {
                lineRender = line.get('value');
            }
        } else if (type === 'url') {
            return <Url value={line.get('value')} relationFieldEditIcon={this.createRelationFieldEditIcon()}/>;
        } else if (type === 'currency') {
            let allowTitle = false;
            if (row.def.get('name') === 'amount_usdollar') {
                allowTitle = true;
            }
            return (
                <Currency
                    value={line.get('value')}
                    currency={line.get('currency_id') ?? line.get('_currency_id')}
                    currencies={sAction.currencies}
                    allowTitle = {allowTitle}
                    // onClick={(e) => this.actEdit(e)}
                    relationFieldEditIcon={this.createRelationFieldEditIcon()}
                />
            );
        } else if (type === 'enum') {
            return (
                <Enum
                    option={sAction.app_strings[row.def.get('options')]}
                    colors={sAction.app_strings[row.def.get('colors')]}
                    value={line.get('value')}
                    bcColor={bcColor}
                    extraClass={extraClass}
                    // onClick={(e) => this.actEdit(e)}
                    relationFieldEditIcon={this.createRelationFieldEditIcon()}
                />
            );
        } else if (type === 'AcmDynamicEnum') {
            let dynEnumOptions = {};
            if (sAction?.app_strings['dynamicEnum'] !== undefined && row?.def?.get('options') !== undefined) {
                dynEnumOptions = sAction.app_strings['dynamicEnum'][row.def.get('options')];
            } else {
                console.error('Chybějící dynamický číselník');
            }
            return (
                <Enum
                    option={dynEnumOptions}
                    colors={sAction.app_strings[row.def.get('colors')]}
                    value={line.get('value')}
                    bcColor={bcColor}
                    extraClass={extraClass}
                    // onClick={(e) => this.actEdit(e)}
                    relationFieldEditIcon={this.createRelationFieldEditIcon()}
                />
            );
        } else if (type === 'multienum') {
            return (
                <Multienum
                    options={sAction.app_strings[row.def.get('options')]}
                    value={line.get('value')}
                    bcColor={bcColor}
                    extraClass={extraClass}
                    // onClick={(e) => this.actEdit(e)}
                    relationFieldEditIcon={this.createRelationFieldEditIcon()}
                />
            );
        } else if (type === 'date') {
            return (
                <Date
                    value={sAction.dateFormat(line.get('value'))}
                    // onClick={(e) => this.actEdit(e)}
                    relationFieldEditIcon={this.createRelationFieldEditIcon()}
                />
            );
        } else if (type === 'datetime' || type === 'datetimecombo') {
            return (
                <Datetime
                    value={sAction.dateFormat(line.get('value'))}
                    // onClick={(e) => this.actEdit(e)}
                    module={modul}
                    prefix={prefix}
                    index={index}
                    filedName={row.def.get('name')}
                    relationFieldEditIcon={this.createRelationFieldEditIcon()}
                />
            );
        } else if (type === 'relate') {
            return <Relate
                value={line.get('value')}
                id={line.get('id')}
                module={line.get('module')}
                // onClick={(e) => this.actEdit(e)}
            />;
        } else if (type === 'multirelate') {
            return <Multirelate value={line} module={modul} targetModule={row.def.get('targetModule')} />;
        } else if (row.def.get('name') === 'currency_id' || row.def.get('name') === '_currency_id' || type === 'currency_id') {
            return (
                <CurrencyId
                    value={line.get('value')}
                    currencies={sAction.currencies}
                    // onClick={(e) => this.actEdit(e)}
                    relationFieldEditIcon={this.createRelationFieldEditIcon()}
                />
            );
        } else if (type === 'parent') {
            return <Relate
                value={line.get('value')}
                id={line.get('id')}
                module={line.get('module')}
                // onClick={(e) => this.actEdit(e)}
            />;
        } else if (type === 'bool') {
            const isActive =
        line.get('value') === '1' ?
            sAction.translate('LBL_STATUS_ACTIVE_LIST') :
            sAction.translate('LBL_STATUS_INACTIVE_LIST');
            lineRender = <span title={isActive}>{isActive}</span>;
        } else if (type === 'icon') {
            lineRender = (
                <span className="iconListviewContainer">
                    <div className={'icon-' + line.get('module')} />
                </span>
            );
        } else if (type === 'image') {
            lineRender = (
                <div style={{overflow: 'hidden', textOverflow: 'ellipsis'}}
                    className="link"
                    onClick={() => sAction.popup('image', {url: sAction.param.files+'image/'+line.get('value')})}
                >
                    {line.get('value')}
                </div>
            );
        } else if (type === 'phone') {
            const countryCode = getCountryCallingCode(sAction.dataGet('conf/language').split('_')[1].toUpperCase());
            lineRender = (
                <a href={'callto:'+line.get('value')}>
                    {formatPhoneNumberIntl(line.get('value')) ||
                        formatPhoneNumber(`+${countryCode}${line.get('value')}`) ||
                        line.get('value')
                    }
                </a>
            );
        } else if (
            type === 'float' ||
        type === 'double' ||
        type === 'decimal'
        ) {
            extraClass = 'listViewCellRight';
            lineRender = <span title={line.get('value')}>{sAction.formatNumber(line.get('value'))}</span>;
        } else if (
            type === 'TimeFloat'
        ) {
            extraClass = 'listViewCellRight';

            const displayType = row.def?.get('displayType')??'h:m';
            let value = '';
            if (displayType) {
                value = sAction.formatNumberToHours(line.get('value'), displayType);
            }
            lineRender = <span title={value}>{value}</span>;
        } else if (
            type === 'int' ||
        type === 'integer'
        ) {
            extraClass = 'listViewCellRight';
            lineRender = <span title={line.get('value')}>{sAction.formatNumber(line.get('value'), 0)}</span>;
        } else if (type === 'Ss') {
            lineRender = <span title={line.get('value')}>{line.get('value')}</span>;
        } else {
            const compareKey = key.toLowerCase();
            if (compareKey === 'email' || compareKey === 'email1') {
                lineRender = <a title={line.get('value')} onClick={(e) => {
                    e.stopPropagation();
                    sAction.openEmailPopup(
                        {
                            to_addrs: line.get('value'),
                            parent_id: this.props.getIdByIndex(index),
                            parent_name: this.props.getPropertyValueByIndex(index, 'NAME'),
                            parent_type: modul,
                        },
                    );
                }}>{line.get('value')}</a>;
            } else {
                lineRender = <span title={line.get('value')}>{line.get('value')}</span>;
            }
        }
        return (
            <div
                className={'newListViewLine ' + extraClass}
                // onClick={(e) => this.actEdit(e)}
            >
                <div className={'withIconContainer w100'}>
                    <div>{lineRender}</div>
                    {this.createRelationFieldEditIcon()}
                </div>
            </div>
        );
    }
}

NewListViewField.propTypes = {
    bcColor: PropTypes.string,
    disabled: PropTypes.bool.isRequired,
    editModule: PropTypes.string.isRequired,
    forceRightPanel: PropTypes.bool,
    getIdByIndex: PropTypes.func,
    getPropertyValueByIndex: PropTypes.func,
    index: PropTypes.number.isRequired,
    line: ImmutablePropTypes.mapContains({
        actEdit: PropTypes.bool,
        id: PropTypes.string,
        module: PropTypes.string,
        value: PropTypes.string,
    }).isRequired,
    listType: PropTypes.string.isRequired,
    modul: PropTypes.string,
    prefix: PropTypes.string.isRequired,
    rightPanelData: PropTypes.object,
    row: ImmutablePropTypes.recordOf({
        clientWidth: PropTypes.number,
        def: ImmutablePropTypes.mapContains({
            colors: PropTypes.string,
            editInline: PropTypes.bool,
            fieldModule: PropTypes.string,
            name: PropTypes.string,
            options: PropTypes.string,
            readonly: PropTypes.bool,
            targetModule: PropTypes.string,
            type: PropTypes.string,
        }).isRequired,
        key: PropTypes.string.isRequired,
        style: PropTypes.style,
    }).isRequired,
    rowIndex: PropTypes.number.isRequired,
    selectCallBack: PropTypes.func,
    target: PropTypes.object,
    uuid: PropTypes.string,
};
