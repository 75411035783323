import React from 'react';
import BoundModuleDetail from './BoundModuleDetail';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';

export default function BoundModules(props) {
    return props.boundModules.map((moduleData, index) => {
        return (
            <BoundModuleDetail
                prefix={`${props.prefix}/${index}`}
                data={moduleData}
                index={index}
                key={index}
                module={moduleData.module}
                id={moduleData.id}
                attemptedSave={moduleData.attemptedSave}
                acl={moduleData.acl}
                customData={moduleData.customData}
                tab={moduleData.tabs}
            />
        );
    });
}

BoundModules.propTypes = {
    boundModules: ImmutablePropTypes.listOf(
        ImmutablePropTypes.record,
    ),
    prefix: PropTypes.string,
};
