import React from 'react';

import MaterialDefault from './MaterialDefault';
import InputText from './InputText';
import sAction from 'sAction';
import Autocomplete from './Autocomplete';
import TooltipWrapper from 'ROOT/src/components/Tooltip/TooltipWrapper';

export default class Relate extends MaterialDefault {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            state: 'fine',
            value: props.data.value,
            id: null,
            resultOpen: false,
            searchText: '',
        };
        this.input = React.createRef();
        this.autocomplete = React.createRef();
    }

    callAutocomplete = (keyCode) =>{
        if (this.autocomplete?.current) {
            this.autocomplete.current.keyUpHandler(keyCode);
        }
    };

    /**
     *
     * @param {KeyboardEvent} event
     */
    keyUp(event) {
        const keyCode = event.keyCode;
        switch (keyCode) {
            case 40:
            case 38:
                this.callAutocomplete(keyCode);
                return;
            case 27:
                this.cancel();
                return;
            case 13:
                if (event.ctrlKey) {
                    return;
                }
                if (this.state.resultOpen) {
                    if (this.autocomplete?.current) {
                        this.autocomplete.current.keyUpHandler(keyCode);
                    }
                } else {
                    // this.save();
                }
                return;
        }
        if (!this.input?.current?.value) {
            this.setState({resultOpen: false});
        } else {
            this.setState({searchText: this.input.current.value, resultOpen: true});
        }
    }

    /**
     *
     * @param {object} item
     * @param {boolean} doUpdate
     * @param {boolean} cancelEdit
     */
    // eslint-disable-next-line no-unused-vars
    selectItem(item, doUpdate = true, cancelEdit = true) {
        this.setState({
            id: item.id,
            name: item.name,
        });
        this.props.callback(item, this.props.fieldIndex);
        if (item.name) {
            this.input.current.value = item.name;
        }
        this.cancel();
    }

    /**
     *
     */
    focus() {
        if (!this.input?.current?.value) {
            this.setState({resultOpen: false});
        } else {
            this.setState({searchText: this.input.current.value, resultOpen: true});
        }
    }

    /**
     *
     * @param {boolean} selectedActive
     */
    openPopupList(selectedActive = false) {
        const self = this;
        const data = {
            module: this.props.module,
            selectedActive: selectedActive,
            defaultFilter: this.props.defaultFilter,
        };

        sAction.openRelatePopup(data, (returnData) => {
            self.selectItem(returnData);
            sAction.popupHide();
        });
    }
    /**
     *
     */
    chechChange() {
        if (this.state.value !== this.input.current.value) {
            if (this.props.updateField) {
                this.input.current.value = '';
            } else {
                this.props.callback({name: this.input.current.value});
            }
        }
        this.cancel();
    }
    /**
     *
     * @param {Event} e
     */
    onBlur(e) {
        const relTarget = e.relatedTarget;

        if (relTarget == null) {
            this.chechChange();
        } else if (!(e.relatedTarget.className === 'quickSearchResultLine')) {
            this.chechChange();
        }
    }

    /**
     *
     */
    cancel() {
        if (this.searchInterval) {
            clearInterval(this.searchInterval);
        }
        this.setState({
            resultOpen: false,
        });
    }

    storeRelate = (item) =>{
        if (item) {
            this.selectItem(item);
        } else {
            this.setState({resultOpen: false});
            // pokud neni vybrana hodnota z naseptavace zavre se okno a uzivatel muze pokracovat v jinem fieldu
        }
    };

    componentDidMount() {
        this._isMounted = true;
    }
    componentDidUpdate() {
        if (this.props.autoFocus) {
            this.input.current.focus();
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {
        const data = this.props.data;
        const newRecord = this.props.newRecord;
        const containerClassName = this.props.containerClassName ? this.props.containerClassName : null;
        let containerClass = 'inputEditContainer ' + containerClassName;
        let keyAttr = {};
        let selectedActive = false;

        let value = data.value;
        if (newRecord && (value === '' || value === undefined || value === null)) {
            value = '';
        }

        if (this.props.name || this.props.name === '') {
            value = this.props.name;
            selectedActive = true;
            keyAttr = {key: value};
        }

        // const searchResult = this.getSearchResult();

        const containerClassField = 'inputContainer relateField';

        if (!containerClass || (containerClass && !containerClass?.includes('relateField'))) {
            containerClass += ' relateField';
        }

        const btnComp = [];
        // buttons={[{className: "detailInfoIcon",  onClick: this.prodInfo(data.id)}]}
        if (this.props.buttons) {
            this.props.buttons.forEach((btn) => {
                btnComp.push(<div
                    key={btn.id}
                    onClick={() => btn.onClick(btn.params)}
                    className="inputEditButton"
                >
                    <div className={btn.className} />
                </div>);
            });
        }
        return (
            <div
                className={containerClass}
                data-fieldname={data.name}
                id={'input_' + data.name}
                style={this.props.containerStyle ? this.props.containerStyle : null}
            >
                {/* searchResult*/}
                <div className={containerClassField}>
                    <InputText
                        {...keyAttr}
                        onKeyDown={(e) => this.onKeyDown(e)}
                        onKeyUp={(event) => this.keyUp(event)}
                        onFocus={() => this.focus()}
                        onBlur={(e) => this.onBlur(e)}
                        myRef={this.input}
                        id={data.name}
                        defaultValue={value}
                        label={this.props.data.label}
                        InputProps={this.props.InputProps}
                        error={this.props.error}
                        autoFocus={this.props.autoFocus ? this.props.autoFocus : false}
                        className={this.props.inputClassName ? this.props.inputClassName : null}
                        disabled={this.props.disabled}
                    />
                    {this.state.resultOpen ? <Autocomplete
                        data={data}
                        resultOpen={this.state.resultOpen}
                        searchText={this.state.searchText}
                        searchMethod={'formRelate'}
                        autocompleteReturnItem={this.storeRelate}
                        newRecord={this.newRecord}
                        ref={this.autocomplete}
                        module={this.props.module}
                    /> : null}
                </div>
                {(!newRecord && !this.props.disabled) && (
                    <div className="buttonContainer">
                        <TooltipWrapper label={'LBL_SEARCHED'}>
                            <div
                                onClick={() => this.openPopupList(selectedActive)}
                                className="inputEditButton openPopupButton"
                            >
                                {!this.props.hideSearchIcon && (<div className={'icon-search relateSearchIcon'} />)}
                            </div>
                        </TooltipWrapper>
                        {btnComp}
                    </div>
                )}
            </div>
        );
    }
}
