import {Map, List} from 'immutable';
import {DataConstructors} from '../../../DataConstructors';

import createListViewTree from '../listView/createListViewTree';
import sAction from 'sAction';

/**
 * processes subpanel data
 * @param {object} data data from rest api to process
 * @param {*} color
 * @param {number} numberOfRecords
 * @return {
 *     Immutable.Record<{
 *         def: Immutable.Map<unknown, unknown>,
 *         module: null,
 *         rows: Immutable.List<unknown>,
 *         moreOptions: Immutable.List<unknown>
 *     }> & Readonly<{
 *         def: Immutable.Map<unknown, unknown>,
 *         module: null,
 *         rows: Immutable.List<unknown>,
 *         moreOptions: Immutable.List<unknown>
 *     }>
 * }
 */
export default function processSubpanel(data, color, numberOfRecords) {
    const subpanel = data.subpanel;
    const def = subpanel.defs;
    const rows = subpanel.rows;
    const records = subpanel.records;
    const readonly = subpanel.readonly;

    const columnsRecord = {};
    const newData = [];
    const recordsPole = [];
    let recordsCount = null;
    if (subpanel.columns != null) {
        subpanel.columns.forEach((column) => {
            columnsRecord[column.field] = [];
            const fieldName = column.field.toLowerCase();
            const fieldDef = def[column.field];

            records?.forEach((record) => {
                if (fieldDef !== undefined) {
                    const fieldData = {
                        value: record[fieldName],
                    };
                    // ___________________________ CUSTOM DATA K FIELDU
                    if (fieldName === 'name' || fieldName === 'full_name' || fieldName === 'document_name' || fieldName === 'filename') {
                        fieldData.id = record.id;
                        fieldData.module = record.module;
                        if (fieldName === 'filename') {
                            if (column.fModule !== 'DocumentRevisions') {
                                fieldData.value = record.document_name ?? (record.revision ?? '___');
                            }
                        }
                        recordsPole.push({record: record.id, module: record.module});
                    }
                    if (fieldDef.type === 'currency') {
                        fieldData.currency_id = record.currency_id;
                        fieldData._currency_id = record._currency_id;
                    }
                    if (fieldDef.type === 'parent') {
                        fieldData.id = record.parent_id;
                        fieldData.module = record.parent_type;
                    }
                    if (fieldDef.type === 'relate') {
                        let id = record[fieldDef.link+'|id'];
                        if (id == null) {
                            id = record[fieldDef.id_name];
                        }
                        fieldData.module = fieldDef.module;
                        fieldData.id = id;
                    }
                    if(column.relationField){
                        fieldDef.relationField = column.relationField;
                    }
                    // ___________________________ CUSTOM DATA K FIELDU

                    columnsRecord[fieldName].push(new Map(fieldData));
                } else if (column.type === 'icon') {
                    const fieldData = {
                        module: record.module,
                    };
                    columnsRecord[fieldName].push(new Map(fieldData));
                }
            });
            if (fieldDef != null && (column.vname !== undefined || column.label !== undefined)) {
                fieldDef['fieldModule'] = subpanel.module;
                newData.push(
                    new DataConstructors['ListViewRow']({
                        key: fieldName,
                        label: column.vname ?? column.label,
                        width: column.width,
                        def: new Map(fieldDef),
                        records: new List(columnsRecord[fieldName]),
                        sortable: column.sortable ?? true,
                        disabled: column.disabled ?? false,
                    }),
                );
                recordsCount = columnsRecord[fieldName].length;
            }
        });
    } else if (rows != null) {
        rows.forEachObject((row, rowKey) => {
            columnsRecord[rowKey] = [];
            const fieldName = rowKey.toLowerCase();
            const fieldDef = def[fieldName];

            records.forEach((record) => {
                if (fieldDef !== undefined) {
                    const fieldData = {
                        value: record[rowKey.toLowerCase()],
                    };
                    // ___________________________ CUSTOM DATA K FIELDU
                    if (fieldName === 'name' || fieldName === 'full_name' || fieldName === 'document_name') {
                        fieldData.id = record.id;
                        fieldData.module = record.module;
                        recordsPole.push({record: record.id, module: record.module});
                    }
                    if (fieldDef.type === 'currency') {
                        fieldData.currency_id = record.currency_id;
                    }
                    if (fieldDef.type === 'parent') {
                        fieldData.id = record.parent_id;
                        fieldData.module = record.parent_type;
                    }
                    if (fieldDef.type === 'relate') {
                        let id = record[fieldDef.link+'|id'];
                        if (id == null) {
                            id = record[fieldDef.id_name];
                        }
                        fieldData.module = fieldDef.module;
                        fieldData.id = id;
                    }
                    // ___________________________ CUSTOM DATA K FIELDU

                    columnsRecord[rowKey].push(new Map(fieldData));
                } else if (row.type === 'icon') {
                    const fieldData = {
                        module: record.module,
                    };
                    columnsRecord[rowKey].push(new Map(fieldData));
                }
            });
            if (fieldDef != null && (row.vname !== undefined || row.label !== undefined)) {
                fieldDef['fieldModule'] = subpanel.module;
                newData.push(
                    new DataConstructors['ListViewRow']({
                        key: rowKey,
                        label: row.vname ?? row.label,
                        width: row.width,
                        def: new Map(fieldDef),
                        records: new List(columnsRecord[rowKey]),
                        sortable: row.sortable ?? true,
                        disabled: row.disabled ?? false,
                    }),
                );
                recordsCount = columnsRecord[rowKey].length;
            } else if (row.type === 'icon') {
                newData.push(
                    new DataConstructors['ListViewRow']({
                        key: rowKey,
                        label: row.vname,
                        width: row.width,
                        def: new Map({fieldModule: data.module, type: 'icon', name: 'module'}),
                        records: new List(columnsRecord[rowKey]),
                        sortable: row.sortable,
                    }),
                );
            }
        });
    }

    // ______________________________________ LISTVIEW TREE
    let treeData = {};
    if (subpanel.listViewTree != null) {
        const tree = createListViewTree(subpanel.listViewTree.tree, true);
        treeData = {
            module: subpanel.listViewTree.module,
            name: subpanel.listViewTree.name,
            field: subpanel.listViewTree.field,
            relationship: subpanel.listViewTree.relationship,
            tree: new List([tree]),
        };
    }
    // ______________________________________ LISTVIEW TREE END

    // COLORS
    const listColors = {};
    if (subpanel.colors != null) {
        subpanel.colors.forEachObject((colors, key) => {
            listColors[key] = new Map(colors);
        });
    }

    let defaultType = 'eq';

    // cemat, aby na centralach sli videt i zaznamy z pobocek (podle parent_id)
    if (data.module === 'Accounts') {
        const lsData = sAction.getStorage('Accounts-subpanel-branch');

        if (lsData && lsData[data.subpanel.rel_name] === 1) {
            defaultType = 'eqParentEq';
        }
    }

    // COLORS
    const defaultFilter = {
        operandType: 'relate',
        type: defaultType,
        module: data.module,
        relName: subpanel.rel_name,
    };
    const relDefinition = {
        type: 'relate',
        module: data.module,
        relName: subpanel.rel_name,
    };
    if (def != null) {
        def.forEachObject((field, key) => {
            if (
                (field?.link === data.subpanel.rel_name) &&
                field.rname === 'name'
            ) {
                relDefinition.relationship = [field.link];
                relDefinition.name = field.name;
                relDefinition.id_name = field.id_name;
                relDefinition.relName = subpanel.rel_name;

                defaultFilter.relationship = [field.link];
                defaultFilter.name = field.name;
                defaultFilter.relName = subpanel.rel_name;
            } else if (
                field.link === data.module.toLowerCase() &&
                field.rname === 'name'
            ) {
                relDefinition.relationship = [field.link];
                relDefinition.name = field.name;
                relDefinition.id_name = field.id_name;
                relDefinition.relName = subpanel.rel_name;

                defaultFilter.relationship = [field.link];
                defaultFilter.name = field.name;
                defaultFilter.relName = subpanel.rel_name;
            } else if (field?.type === 'link' && field.module === data.module) {
                if (relDefinition.relationship == null) {
                    relDefinition.relationship = [field.relationship];
                    relDefinition.name = field.name;
                    relDefinition.relName = subpanel.rel_name;
                    defaultFilter.relationship = [field.relationship];
                    defaultFilter.name = field.name;
                    defaultFilter.relName = subpanel.rel_name;
                }
            } else if (key === data.subpanel.rel_name) {
                defaultFilter.relationship = [field.relationship];
                defaultFilter.name = field.name;
                defaultFilter.relName = key;
            }
        });
    }
    if (defaultFilter['relationship'] == null) {
        defaultFilter['relationship'] = [data.module.toLowerCase()];
    }
    defaultFilter.parentId = data.parentId;

    const defaultFilterData = {
        subpanel: defaultFilter,
    };
    if (subpanel.listViewTree != null) {
        defaultFilterData['tree'] = {
            module: subpanel.listViewTree.module,
            name: subpanel.listViewTree.name,
            field: subpanel.listViewTree.field,
            relationship: subpanel.listViewTree.relationship,
            operandType: 'relate',
            type: 'contArray',
        };
    }

    let order = null;
    let asc = null;
    if (subpanel.orderBy != null) {
        const orderData = subpanel.orderBy;
        order = orderData.field.replace(/ /g, '').split(',');
        asc = orderData.direction.toLowerCase() === 'asc' ? 1 : 0;
    }

    const list = new DataConstructors['ListView']({
        rowCount: recordsCount,
        offset: 0,
        limit: numberOfRecords ? parseInt(numberOfRecords) : 10,
        orderBy: order,
        asc: asc,
        modul: subpanel.module,
        rows: new List(newData),
        records: new List(recordsPole),
        colors: new Map(listColors),
        type: 'subpanel',
        listViewTree: new DataConstructors.listViewTree(treeData), // eslint-disable-line new-cap
        defaultFilter: new Map(defaultFilterData),
        rowTotalCount: subpanel.rowTotalCount ?? null,
        filter: sAction.prepareFilterStructure(data.filter),
        actFiltering: data.actFiltering,
    });

    const moreOptions = subpanel.more_options ? new Map(subpanel.more_options) : null;

    let subpanelData = new DataConstructors['subpanelDef']({ // eslint-disable-line new-cap
        module: subpanel.module,
        rows: list,
        moreOptions: moreOptions,
        rel_name: subpanel.rel_name,
    });
    subpanelData = subpanelData.setIn(['def', 'color'], color);
    subpanelData = subpanelData.setIn(['def', 'readonly'], readonly);
    subpanelData = subpanelData.setIn(['def', 'open'], subpanel.open);
    subpanelData = subpanelData.setIn(['def', 'rel'], new Map(relDefinition));
    if (subpanel.translations) {
        subpanelData = subpanelData.setIn(['def', 'translations'], new Map(subpanel.translations));
    }

    return subpanelData;
}
