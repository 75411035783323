import sAction from 'sAction';

/**
 * way prijde z QProduct v formatu view/customData/productData/groups/0/lines/2/price_after_discount
 * vzdy musi vracet bool pokud nechci aby se kontrolovala kompetence nebo pokud nespada pod false.
 * @param {string} way
 * @param {object} line
 * @return {boolean}
 * @this sAction
 * @TODO Stahnout aktualni verzi az bude na masteru
 */
export default function checkCompetence(way, line) {
    const competenceRole = this.dataGet('conf/user/competence_role');
    // Pokud jsem reditel nebo admin muzu davat cenou jakou chci
    if (competenceRole === 'director' || competenceRole === 'admin' || sAction.isAdmin()) {
        return false;
    }

    const sum = calculateSum(line);
    let intCompetence = 0;

    if (competenceRole === 'manager') {
        intCompetence = parseFloat(line.kompetence_manager);
    } else if (competenceRole === 'salesman') {
        intCompetence = parseFloat(line.kompetence_obchodnik);
    }

    if (sum < intCompetence) {
        this.dataSet(`${way.split('/')[0]}/isUnderCompetencePrice`, true);

        return true;
    }

    this.dataSet(`${way.split('/')[0]}/isUnderCompetencePrice`, false);

    return false;
}

/**
 * @param {object} line
 * @return {float}
 */
function calculateSum(line) {
    if (parseFloat(line.discount_select)) {
        return line.list_price * ((100 - line.price_after_add_discount) / 100);
    }

    return line.list_price - line.price_after_add_discount;
}
