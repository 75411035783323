import React from 'react';
import PropTypes from 'prop-types';
import sAction from 'sAction';
import InputText from './InputText';

export default class InputFloat extends InputText {
    constructor(props) {
        super(props);
        this.state = {
            value: props.defaultValue ?? 0,
        };
    }
    render() {
        const value = this.state.value || this.props.defaultValue;

        return (
            <InputText
                {...this.props}
                type={'text'}
                className={this.props.className +' alignRight'}
                defaultValue={sAction.formatNumber(value)}
                key={value}
                onBlur={(e) =>{
                    e.target.value = sAction.parseNumber(e.target.value);
                    this.setState({value: e.target.value});
                    this.props.onBlur(e);
                }}
            />
        );
    }
}

InputFloat.propTypes = {
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onBlur: PropTypes.func, // pass custom save function to store or another place you want
    disabled: PropTypes.bool,
};
