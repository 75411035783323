import React, {PureComponent} from 'react';
import sAction from 'sAction';
import CustomLine from './CustomLine';
import Button from '../../formElements/Button';
import CustomLinesHeader from './CustomLinesHeader';
import CustomLinesTotal from './CustomLinesTotal';
import PropTypes from 'prop-types';

export default class CustomDetailLines extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {index: 0, customLines: []};
        this.keyArray = {};
    }

    /**
     * Add line - static because of bind products on acm_service_order
     *
     * @param {object} def - definition of detailLines from BE file
     * @param {object} moduleData - fields definitions of line module
     * @param {string} way
     * @param {string|number} index index of new line
     * @param {boolean} executeImmediately if set on false, add only to dataStack and execute manually from other code
     */
    static addCustomLine(def, moduleData, way = '', index = null, executeImmediately = true) {
        const defaultData = {};
        def.get('fields').toJS().forEachObject((field) => {
            if (def.get('default') && def.get('default').get(field)) {
                defaultData[field] = def.get('default').get(field);
            } else if (moduleData.get(field) && moduleData.get(field).get && moduleData.get(field).get('default')) {
                defaultData[field] = moduleData.get(field).get('default');
            }
        });
        def.get('default').toJS().forEachObject((value, column) => {
            defaultData[column] = value;
        });

        // Generates some 'id' for new line to have unique key
        if (!defaultData.lineKeyId) {
            defaultData.lineKeyId = (Math.random() * 1000000000).toFixed(0); // round to whole number
        }

        sAction.dsClear();
        sAction.dsAdd('set', way + '/customData/customLines/lines/' + index, defaultData);
        sAction.dsAdd('set', way + '/changes/forceChange', true);
        if(executeImmediately === true){
            sAction.dsProcess();
        }
        return index + 1;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const lines = this.props.data.get('lines');
        // update index, when we added line with this.addCustomLine
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.index = lines.size ?? lines.length ?? this.state.index;
    }

    /**
     * @param {object} moduleData
     * @param {string} way
     * @param {object|array} lines
     * @returns {JSX}
     */
    getTotal(moduleData, way, lines) {
        return (
            <CustomLinesTotal
                key={`${this.props.id}`} // when changing between parent records, we want rerender data based on record id
                def={this.props.data.get('def')}
                way={way}
                lines={lines}
                moduleData={moduleData}
                info={this.props.data.get('info')}
            />
        );
    }

    /**
     * @param {number} key
     * @param {string} direction
     */
    moveElement(key, direction) {
        const way = this.props.way + '/customData/customLines/lines';

        let list = sAction.dataGet(way);
        const cnt = list.size;

        sAction.dsClear();

        if (direction === 'up') {
            if (key === 0 || key === '0') {
                return;
            }

            const pomProd = list.get(this.keyArray[key]);
            list = list.delete(this.keyArray[key]);
            list = list.insert(this.keyArray[key - 1], pomProd);

            sAction.dsAdd('set', way, list);
        } else if (direction === 'down') {
            if ((key + 1) >= cnt) {
                return;
            }

            const pomProd = list.get(this.keyArray[key]);
            list = list.delete(this.keyArray[key]);
            list = list.insert(this.keyArray[key + 1], pomProd);

            sAction.dsAdd('set', way, list);
        }

        sAction.dsAdd('set', 'view/changes/forceChange', true);
        sAction.dsProcess();
    }

    /**
     *
     */
    openListView() {
        const module = this.props.data.get('info').get('module');
        const data = {
            module: module,
            selectedActive: false,
        };

        const relFieldName = this.props.data.get('info').get('module_rel_field');
        data.defaultFilter = [
            {
                module: module,
                operandType: 'relate',
                parentId: this.props.id,
                relName: this.props.data.get('moduleData').get(relFieldName).get('relationship'),
                relationship: [this.props.data.get('moduleData').get(relFieldName).get('relationship')],
                type: 'eq',
            },
        ];

        sAction.openRelatePopup(data, (returnData) => {
            window.open(`#detail/${module}/${returnData.id}`, '_blank');
        });
    }

    render() {
        const info = this.props.data.get('info');
        const way = this.props.way;
        const lines = this.props.data.get('lines');
        const def = this.props.data.get('def');
        const acl = this.props.acl;
        const readonly = this.props.readonly || def?.get('readonly');
        const moduleData = this.props.data.get('moduleData');
        const timeline = this.props.data.get('timeline');
        const allLinesCount = this.props.data?.get('linesCount');
        const recordsLimit = this.props.data.get('info').get('recordsLimit');

        let visible = def.get('visible') ?? true;
        if ( visible === 'false') {
            visible = false;
        }
        let index = 0;
        const customLines = [];
        if (lines && visible) {
            lines.forEach((line, key) => {
                if (line.get('deleted') !== 1 && line.get('deleted') !== '1') {
                    this.keyArray[index] = key;
                    customLines.push(
                        <CustomLine
                            data={line}
                            def={def}
                            key={line?.get('id') || line?.get('lineKeyId')}
                            lineKey={index}
                            moduleData={moduleData}
                            showError={this.props.data.get('showError')}
                            way={way}
                            canEdit={acl.get('edit')}
                            timeline={timeline}
                            info={info}
                            readonly={readonly}
                            moveElement={(key, direction) => {
                                this.moveElement(key, direction);
                            }}
                        />,
                    );
                }
                index++;
            });
        }
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.index = index;

        let totalLine = null;
        if (def.get('total') && def.get('total').toJS().length > 0) {
            totalLine = this.getTotal(moduleData, way, lines);
        }

        const headerPom = (
            <CustomLinesHeader
                def={def}
                moduleData={moduleData}
                info={info}
            />
        );

        return (
            <div className='detailCard detailViewTabContent detailQuoteProdComponent'>
                <div className='subpanelsContainer' data-way={this.props.way + '/customData/customLines'}>
                    <div className="qGroupHeaderContainer header">
                        <div className="title">
                            <span className={'detailViewHeaderIcon icon-' + info.get('module')}/>
                            <span className="detailViewName">
                                {sAction.translate('LBL_MODULE_NAME', info.get('module'))}
                            </span>
                        </div>
                    </div>
                    <div className="detailView">
                        <div className="detailViewTabContent customLines">
                            {headerPom}
                            {customLines}
                            {totalLine}
                            {visible && acl.get('edit') && (
                                <>
                                    <div className='customLinesRowContainer flexRight viewActionPanelButtons'>
                                        <div style={{display: 'flex', width: 'auto'}}>
                                            {recordsLimit && allLinesCount > recordsLimit ? (
                                                <Button className="acmButton" onClick={() => this.openListView(info)}>
                                                    <div className={'actionPanelButtonIcon icon-listview'}/>
                                                    {sAction.translate('LBL_SHOW_ALL_CUSTOM_LINES')}
                                                </Button>
                                            ) : null}
                                            {!readonly ? (
                                                <Button className="acmButton"
                                                    onClick={() => {
                                                        const newIndex = CustomDetailLines.addCustomLine(def, moduleData, this.props.way, this.state.index);
                                                        this.setState({index: newIndex});
                                                    }}>
                                                    <div className={'actionPanelButtonIcon icon-addIcon'}/>
                                                    {sAction.translate('LBL_ADD_CUSTOM_LINE')}
                                                </Button>
                                            ) : null}
                                        </div>
                                    </div>
                                </>
                            )}
                            {recordsLimit && allLinesCount > recordsLimit ? (
                                <div className='customLinesRowContainer flexRight linesCountContainer'>
                                    <div>
                                        {`${sAction.translate('LBL_DISPLAYED')} ${lines.size} ${sAction.translate(
                                            'LBL_DISPLAYED_FROM')} ${allLinesCount}`}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

CustomDetailLines.propTypes = {
    data: PropTypes.any,
    prefix: PropTypes.string.isRequired,
    way: PropTypes.string.isRequired,
    acl: PropTypes.object,
    readonly: PropTypes.bool,
    id: PropTypes.string,
};
