/**
 * @param {String} url
 * @return {Object} {view: "70bae435-afe6-6ec8-13a4-642142e519be"}
 * @action sAction.getUrlParams()
 * parse url params from coripo url format
 * parameters from url hash in url param format
 * @example url: http://localhost:8080/#list/aon_portal_documents?view=70bae435-afe6-6ec8-13a4-642142e519be
 */
export default function getUrlParams(url = window.location.href) {
    const params = {};
    const urlParamString = url.split('#')[1]?.split('?')[1];

    if (urlParamString) {
        urlParamString.split('&').forEach((param) => {
            const paramParts = param.split('=');
            params[paramParts[0]] = paramParts[1];
        });
    }

    return params;
}
