import PropTypes from 'prop-types';
import React from 'react';
import PureComponent from '../../pure';
import sAction from 'sAction';
import TooltipWrapper from 'ROOT/src/components/Tooltip/TooltipWrapper';

export default class CustomLinesHeader extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {cols: []};
    }

    /**
     *
     * @param {*} col
     * @param {*} key
     * @return {JSX.Element}
     */
    getField(col, key) {
        const moduleData = this.props.moduleData;
        const info = this.props.info;
        const def = this.props.def;

        const title = sAction.translate(moduleData.get(col).get('vname'), info.get('module'));

        let style = null;
        let content = '';

        if (!moduleData.get(col)) {
            console.error('Missing col', col);
        }
        const required = moduleData.get?.(col)?.get?.('required') ? (<span className='required'>*</span>) : null;

        if (def.get && def.get('style') && def.get('style').get && def.get('style').get(col)) {
            style = def.get('style').get(col).toJS();
        }

        // Vzhledem k tomu ze potreba mit na detailu jiny nazev nez na radku, musime zde mit tuto vyjimku
        if (moduleData.get(col).get('vname') === 'LBL_DO_NOT_DISCOUNT') {
            content = 'NSD';
        } else {
            content = [title, required];
        }

        return (
            <div className='customLinesCell' key={key} style={style}>
                <TooltipWrapper title={title} placement={'top'}>
                    {content}
                </TooltipWrapper>
            </div>
        );
    }

    /**
     * @returns {JSX}
     */
    getLineButtons() {
        let minWidth = 0;
        if (sAction.hasAccess(this.props.info.get('module'), 'detail')) {
            if (this.props.info.get('eyeIcon')) {
                minWidth += 14;
            }
            if (this.props.info.get('detailButton')) {
                minWidth += 14;
            }
        }
        if (sAction.hasAccess(this.props.info.get('module'), 'edit')) {
            if (this.props.info.get('orderRow') && this.props.info.get('ordering') === 'manual') {
                minWidth += 14;
            }
            minWidth += 16;
        }

        return (
            <div className='customLinesCell customLinesCellDelete' key={'buttons'} style={{minWidth: minWidth + 'px'}}>
            </div>
        );
    }

    render() {
        const def = this.props.def;

        const cols = [];
        def.get('fields').toJS().forEachObject((col, key) => {
            cols.push(this.getField(col, key));
        });
        const lineButtons = this.getLineButtons();

        return (
            <div className='customLinesRowContainer customLinesHeader'>
                {lineButtons}
                {cols}
            </div>
        );
    }
}

CustomLinesHeader.propTypes = {
    def: PropTypes.shape({
        get: PropTypes.func,
    }),
    info: PropTypes.shape({
        get: PropTypes.func,
    }),
    moduleData: PropTypes.shape({
        get: PropTypes.func,
    }),
};
