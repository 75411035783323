import React from 'react';
import sAction from 'sAction';
import PropTypes from 'prop-types';
import TooltipWrapper from 'ROOT/src/components/Tooltip/TooltipWrapper';

export default function Name(props) {
    let lineRender = null;
    let showEyeIcon = true;
    const value = sAction.decodeHTMLEntities(props.value);

    if (props.module !== 'acm_coripoReports') {
        if (sAction.hasAccess(props.module, 'detail') && props.disabled !== true) {
            lineRender = (
                <a
                    className="strong"
                    id="name"
                    title={value}
                    onClick={(ev) => props.callback(ev)}
                    href={'#detail/' + props.module + '/' + props.id}
                >
                    {value}
                </a>
            );

            if (props.inRightPanel) {
                showEyeIcon = false;
            }
        } else {
            lineRender = <span title={value}>{value}</span>;
            showEyeIcon = false;
        }
    } else {
        lineRender = (
            <a
                onClick={(e) =>{
                    e.stopPropagation();
                }}
                className="strong"
                title={value}
                href={'#customReports/' + props.id}
            >
                {value}
            </a>
        );
    }

    return (
        <div
            className={'newListViewLine ' + props.extraClass}
            onClick={props.onClick}
        >
            {showEyeIcon && (
                <TooltipWrapper label={'LBL_RECORD_PREVIEW'}>
                    <div
                        onClick={() => props.eyeCallBack()}
                        className="icon-eye listViewEyeIcon"
                    />
                </TooltipWrapper>
            )}
            {props.module === 'acm_coripoReports' && (
                <TooltipWrapper label={'LBL_OPEN_IN_NEW_WINDOW'}>
                    <div
                        onClick={() => window.open(`#customReports/${props.id}`, '_blank')}
                        className="icon-newTab listViewEyeIcon"
                    />
                </TooltipWrapper>
            )}
            {lineRender}
        </div>
    );
}

Name.propTypes = {
    module: PropTypes.string.isRequired,
    eyeCallBack: PropTypes.func,
    onClick: PropTypes.func,
    id: PropTypes.string.isRequired,
    extraClass: PropTypes.string,
    inRightPanel: PropTypes.bool,
    value: PropTypes.any,
    disabled: PropTypes.bool,
    callback: PropTypes.func,
};
